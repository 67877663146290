import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import PrimaryLayout from "../components/layout/primary/primary";
import ProductGrid from "../components/product-grid/product-grid";
import { Box, Text } from "theme-ui";
import styles from "../components/shop/shop.style";
import CategoryDropdownMenu from "../components/category-dropdown/category-dropdown";
import ShopBanner from "../components/shop-banner/shop-banner";
import ContentContainer from "../components/container/content-container";
import Pagination from "../components/pagination/pagination";
import { MakeMixedVariantArray } from "../utils";

const CategoryPage = ({ data, pageContext }: any) => {
  const { products, description = ""} = data.shopifyCollection;
  const title = pageContext?.title;
  const meta = description || data.allPrismicShop.edges[0].node.data.meta_description
  const { currentPage, numPages, postsLimit, handle } = pageContext;
  const currentSlug = `/shop/${handle}`;

  const variantsArray = MakeMixedVariantArray(products)
  const activeVariants = variantsArray?.filter((variant) => variant.available === true)
  const sliceIndex = (currentPage - 1) * postsLimit
  const sliceLimit = (postsLimit * (currentPage - 1)) + 12
  const variantsSlice = activeVariants?.slice(sliceIndex, sliceLimit)

  return (
    <PrimaryLayout
      fluid={true}
      homeLink="/"
      shop
      pathPrefix="/shop"
      showNoticeBar={false}
    >
      <SEO title={title} description={meta}/>
      <ShopBanner />
      <CategoryDropdownMenu />
      <ContentContainer width="1078px">
        {description !== "" &&
          <Box sx={styles.description}>
            <p>{description}</p>
          </Box>
        }
        <Box sx={styles.page}>
          <Box className="shop-main">
            {variantsSlice.length > 0 ?
              <ProductGrid
                id="category"
                products={variantsSlice}
                withLink={true}
                isCategoryProduct={true}
                shopPage={true}
              />
              : 
              <Text as="h3" sx={{ 
                color: "#054752",
                padding: "25px 0 35px 15px",
                fontSize: "25px"}}
              >
                No products found!
              </Text>
            }
            {numPages > 1 ?
              <Pagination
                numPages={numPages}
                currentPage={currentPage}
                currentSlug={currentSlug}
                isProductPage={true}
              />
              : 
              <Box sx={{paddingBottom: "30px"}}/>
            }
          </Box>
        </Box>
      </ContentContainer>
      <Box sx={{paddingBottom: "10px"}}/>
    </PrimaryLayout>
  );
};

export const query = graphql`
  query($handle: String!) {
    shopifyCollection(handle: { eq: $handle }) {
      description
      products {
        id
        title
        tags
        handle
        createdAt
        shopifyId
        variants {
          id
          title
          price
          image {
            id
            originalSrc
            gatsbyImageData(
              width: 380,
              layout: CONSTRAINED
            )
          }
          shopifyId
          availableForSale
        }
        images {
          id
          originalSrc
          gatsbyImageData(
            width: 380,
            layout: CONSTRAINED
          )
        }
      }
    }
    allPrismicShop {
      edges {
        node {
          data {
            meta_description
          }
        }
      }
    }
  }
`;

export default CategoryPage;
